import { useQuery } from "@tanstack/react-query";
import { useUserToken } from "../token/TokenQuery";
import { GetBackendClient } from "../../services/BackendClientFactory";
import { TaleIdResource } from "../../generated/BackendApiClient";
import { useUserSettingsQuery } from "../user/settings/UserSettingsQueries";
import { EmptyImage } from "../../services/EmptyImageService";

export const imagesKey = "images"

export const useTaleImageQuery = (taleId: TaleIdResource, visualDescription: string) => {

    const { userToken, isAuthenticated } = useUserToken()
    const { userSettings } = useUserSettingsQuery()

    const { data: taleImage } = useQuery({
        queryKey: [imagesKey, taleId.id, visualDescription],
        queryFn: async () => {
            try {
                const resp = await GetBackendClient(userToken, userSettings).getImage.getImage(taleId.id, visualDescription)
                return resp.data
            } catch (resp: any) {
                if (resp.status === 404) {
                    return EmptyImage
                }
                throw resp
            }
        },
        enabled: isAuthenticated,
        retry: 1
    });

    return { taleImage: taleImage ?? null }
}