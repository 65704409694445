import { faPen, faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useState } from "react"
import { Button, Spinner } from "react-bootstrap"

type TextEditingComponentProps = {
    obj: any,
    textSelector: (obj: any) => string,
    setText: (str: string) => void,
    onSave: () => Promise<any>,
    allowEditing?: boolean
}

export const TextEditingComponent: FC<TextEditingComponentProps> = ({ obj, textSelector, setText, onSave, allowEditing }) => {

    const [editing, setEditing] = useState<boolean>(false)
    const [updating, setUpdating] = useState<boolean>(false)

    const saveOrEditRegion = async () => {
        if (editing) {
            setUpdating(true)
            setEditing(false)
            onSave().then(() => setUpdating(false))
        }

        setEditing(!editing)
    }

    const buttonIcon = editing ? faSave : faPen

    return (
        <div className="text-editing">
            <p
                style={{ textAlign: "justify", width: "100%" }}
                onBlur={(event) => setText(event.currentTarget.innerText)} suppressContentEditableWarning={true}
                contentEditable={editing}>
                {textSelector(obj)}
            </p>
            {allowEditing ?
                <Button disabled={updating} onClick={saveOrEditRegion}>
                    {updating ? <Spinner size="sm" /> : <FontAwesomeIcon icon={buttonIcon} />}
                </Button> :
                <></>}
        </div>
    )
} 