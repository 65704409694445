import { FC } from "react"
import { useTaleImageQuery } from "../stores/image/ImageQueries"
import { TaleIdResource } from "../generated/BackendApiClient"
import { Card } from "react-bootstrap"
import { IsEmptyImage } from "../services/EmptyImageService"

type CardImgByVisualDescriptionProps = {
    visualDescription: string,
    taleId: TaleIdResource,
    fallbackUrl: string
}

export const CardImgByVisualDescription: FC<CardImgByVisualDescriptionProps> = ({ visualDescription, taleId, fallbackUrl }) => {
    const { taleImage } = useTaleImageQuery(taleId, visualDescription)

    const src = taleImage && !IsEmptyImage(taleImage) ? `data:${taleImage.mimeType};base64,${taleImage.base64EncodedData}` : fallbackUrl

    return (
        <Card.Img
            src={src}
            alt={taleImage?.altText}
            variant="top"
        />
    )
}