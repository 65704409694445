import { FC } from "react"
import { RegionComponent } from "../RegionComponent"
import { useSelectedRegion } from "../../services/SelectedRegionHook"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { taleUriBase } from "../../services/UriBuilder"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

export const TaleRegionSubPage: FC = () => {

    const { tale, selectedRegionNode, selectedRegionParent } = useSelectedRegion()
    const nav = useNavigate()

    const extractParentData = () => {
        if (!tale || !selectedRegionParent) {
            return { parentUrl: undefined, parentName: undefined }
        }

        const parentUrl = taleUriBase(tale.id) + '/region/' + selectedRegionParent.region.id.identifier
        const parentName = selectedRegionParent.region.name

        return { parentUrl, parentName }
    }

    const { parentName, parentUrl } = extractParentData()

    return (
        <>
            {parentName && parentUrl ?
                <Button onClick={() => nav(parentUrl)}><FontAwesomeIcon icon={faArrowLeft} /> Goto Parent Region {parentName}</Button> :
                <> </>}
            {selectedRegionNode ?
                <RegionComponent node={selectedRegionNode} taleId={tale.id} /> :
                <p>Not found</p>}
        </>
    )
}