import { FC } from "react";
import { TaleCharacterView } from "./TaleCharacterView";
import { useSelectedCharacter } from "../../services/SelectedElementHook";

export const TaleCharacterSubPage: FC = () => {
    const { tale, containingRegion, character } = useSelectedCharacter()

    if (tale === undefined || containingRegion === undefined || character === undefined) {
        return <div>Loading Character...</div>
    }
    return (<TaleCharacterView character={character} taleId={tale.id} region={containingRegion} />)
}