import { ImageResource } from "../generated/BackendApiClient";

export const EmptyImage: ImageResource = {
    base64EncodedData: "",
    mimeType: "",
    altText: ""
}

export const IsEmptyImage = (image: ImageResource): boolean => {
    return image.base64EncodedData === ""
}