import { FC, useState } from "react"
import { Spinner } from "react-bootstrap"
import { RegionType, RegionTypes } from "../../dtos/RegionTreeExtensions"
import { ProposalResource, RegionTreeNodeResource, TaleIdResource } from "../../generated/BackendApiClient"
import { useCreateRegionsMutator } from "../../stores/tale/regions/RegionsMutators"
import { ManualPrompt } from "../ManualPrompt"
import { ProposalGenerator } from "../ProposalGenerator"
import { HasTalePermission } from "../../util/Permission"
import "./RegionCreationComponent.css"
import { useNavigate } from "react-router-dom"
import { taleUriBase } from "../../services/UriBuilder"

type RegionCreationComponentProps = {
    taleId: TaleIdResource,
    parent?: RegionTreeNodeResource
}

export const RegionCreationComponent: FC<RegionCreationComponentProps> = ({ taleId, parent }) => {

    const availableTypes = parent ? RegionTypes.slice(RegionTypes.indexOf(parent.region.regionType as RegionType) + 1) : RegionTypes
    const [regionType, setRegionType] = useState<RegionType>(availableTypes[0])
    const nav = useNavigate()

    const { createRegionsMutator, creatingRegions } = useCreateRegionsMutator()
    const createNewRegion = async (proposal: ProposalResource, regionType: RegionType) => createRegionsMutator.mutateAsync({ taleId, parentId: parent?.region?.id?.identifier!, regionType, proposal })
        .then(change => nav(taleUriBase(taleId) + '/region/' + change.changedId))
    const editPermission = HasTalePermission(taleId, "EditTale")

    if (!editPermission) {
        <div className="region-creation-component">
            <p>Sorry but you are not allowed to edit this page</p>
        </div>
    }

    return (
        <div className="region-creation-component">
            <select value={regionType} onChange={e => setRegionType(e.target.value as RegionType)}>
                {availableTypes.map(type => <option key={type} value={type}>{type}</option>)}
            </select>
            <ManualPrompt onSelectedProposal={(proposal: ProposalResource) => createNewRegion(proposal, regionType)} costSelection={c => c?.regionCreation} />
            <h3>Generate Proposals</h3>
            <ProposalGenerator taleId={taleId} queryParams={{ regionType: regionType }} onSelectedProposal={(proposal: ProposalResource) => createNewRegion(proposal, regionType)} costSelection={c => c?.regionCreation} type="Region" />
            {creatingRegions ? <div>Creating Region... <Spinner /></div> : <></>}
        </div>
    )
}