import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"
import { Button } from "react-bootstrap"

type DeleteButtonProps = {
    onDelete: () => void | Promise<void>,
    disabled?: boolean,
    text?: string
}

export const DeleteButton: FC<DeleteButtonProps> = ({ onDelete, disabled, text }) => {

    const onClick = () => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            onDelete()
        }
    }

    return (
        <Button disabled={disabled ?? false} onClick={onClick}>
            <FontAwesomeIcon icon={faTrash} />{text}
        </Button>
    )
}