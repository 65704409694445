import { FC } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CardImgByVisualDescription } from "../CardImgByVisualDescription";
import { TaleIdResource } from "../../generated/BackendApiClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";


type CardVerticalScrollContainerProps = {
    items: {
        id: { identifier: string },
        name: string
        visualDescription: string
    }[];
    taleId: TaleIdResource;
    baseUrl: string;
    fallBackImage: (item: any) => string;
    allowCreation?: boolean;
    creationUrl?: string;
}

export const CardVerticalScrollContainer: FC<CardVerticalScrollContainerProps> = ({ items, taleId, baseUrl, fallBackImage, allowCreation, creationUrl }) => {
    return (
        <div className="vertical-scroll-container">
            {!allowCreation && items.length === 0 && <div>Nothing found</div>}
            {
                allowCreation &&
                <Link key="create" to={creationUrl ?? (baseUrl + "create")}>
                    <Card>
                        <Card.Header><Card.Title>Create new</Card.Title></Card.Header>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                            <FontAwesomeIcon icon={faPlus} size="2xl" />
                        </div>
                    </Card>
                </Link>
            }
            {items.map(item => (
                <Link key={item.id!.identifier} to={baseUrl + item.id.identifier}>
                    <Card>
                        <Card.Header><Card.Title>{item.name}</Card.Title></Card.Header>
                        <CardImgByVisualDescription taleId={taleId} visualDescription={item.visualDescription} fallbackUrl={fallBackImage(item)} />
                    </Card>
                </Link>)
            )}
        </div>
    )
}