import { FC } from "react"
import { ProposalResource } from "../../generated/BackendApiClient"
import { useSelectedRegion } from "../../services/SelectedRegionHook"
import { useCreateCharacterMutator } from "../../stores/tale/characters/CharacterMutators"
import { Spinner } from "react-bootstrap"
import { ManualPrompt } from "../ManualPrompt"
import { ProposalGenerator } from "../ProposalGenerator"
import { taleUriBase } from "../../services/UriBuilder"
import { useNavigate } from "react-router-dom"

export const TaleCharacterCreationSubPage: FC = () => {
    const { tale, selectedRegion } = useSelectedRegion()
    const { createCharacterMutator, creatingCharacter } = useCreateCharacterMutator()
    const nav = useNavigate()

    if (tale === undefined || selectedRegion === undefined) {
        return <div>Loading...</div>
    }

    const taleId = tale.id
    const regionId = selectedRegion.id
    const createNewCharacter = async (proposal: ProposalResource) => createCharacterMutator.mutateAsync({ taleId, regionId, proposal })
        .then(change => nav(taleUriBase(taleId) + '/characters/' + change.changedId))

    return (
        <>
            <h2>Create New Character</h2>
            {<ManualPrompt onSelectedProposal={createNewCharacter} costSelection={c => c?.characterCreation} />}
            {<h3>Generate Proposals</h3>}
            {<ProposalGenerator taleId={taleId} onSelectedProposal={createNewCharacter} costSelection={c => c?.characterCreation} type="Character" />}
            {creatingCharacter ? <div>Creating Character... <Spinner /></div> : <></>}
        </>
    )
}