import { FC } from "react";
import { TaleImageFrame } from "../TaleImageFrame";
import { useRemoveRegionMutator, useUpdateRegionsMutator } from "../../stores/tale/regions/RegionsMutators";
import { RegionResource, RegionTreeNodeResource, TaleIdResource } from "../../generated/BackendApiClient";
import { HasTalePermission } from "../../util/Permission";
import "./TaleRegionView.css"
import { taleUriBase } from "../../services/UriBuilder";
import { DeleteButton } from "../DeleteButton";
import { Pad } from "../Pad";
import { CardVerticalScrollContainer } from "./CardVerticalScrollContainer";
import { DummyImages } from "../../services/DummyImages";
import { TextEditingComponent } from "../TextEditingConponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDragon, faGlobe, faPerson, faTools } from "@fortawesome/free-solid-svg-icons";

interface TaleRegionViewProps {
    node: RegionTreeNodeResource,
    taleId: TaleIdResource
}

export const TaleRegionView: FC<TaleRegionViewProps> = ({ node, taleId }) => {
    const region = node.region
    const { updateRegionsMutator } = useUpdateRegionsMutator()
    const { removeRegionMutator, removingRegion } = useRemoveRegionMutator()



    const removeRegion = async () => {
        await removeRegionMutator.mutateAsync({ regionId: region.id, taleId })
    }

    return (
        <div id={'region_' + region.id.identifier} className="tale-region-view" style={{ display: "flex", flexDirection: "column", margin: "24px 24px 24px 0px" }}>
            {region.id.identifier && <>
                <div style={{ display: "flex", alignItems: "flex-end", marginBottom: "16px" }}>
                    <h2>Region {region.name} - {region.regionType}</h2>
                    <Pad padding={10} />
                    <DeleteButton onDelete={removeRegion} disabled={removingRegion} />
                </div>

                <div style={{ display: "flex", flexDirection: "row", position: "relative", justifyContent: "space-between" }}>
                    <div style={{ float: "right", height: "max-content", alignItems: "center", display: "flex", flexDirection: "column", margin: "0px 12px 12px 0px", backgroundColor: "#b93f45" }}>
                        <div style={{ margin: "8px", width: "max-content" }}>
                            <TaleImageFrame taleId={taleId} visualDescription={region.visualDescription} />
                        </div>
                    </div>
                    <TextEditingComponent
                        obj={region}
                        textSelector={(r: RegionResource) => r.abstract}
                        setText={(str: string) => region.abstract = str}
                        allowEditing={HasTalePermission(taleId, "EditTale")}
                        onSave={() => updateRegionsMutator.mutateAsync({ region, taleId })}
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "row", position: "relative", gap: "2rem" }}>
                    <div style={{ width: "50%" }}>
                        <h3>Description</h3>
                        <TextEditingComponent
                            obj={region}
                            textSelector={(r: RegionResource) => r.description}
                            setText={(str: string) => region.description = str}
                            allowEditing={HasTalePermission(taleId, "EditTale")}
                            onSave={() => updateRegionsMutator.mutateAsync({ region, taleId })}
                        />
                    </div>
                    <div style={{ width: "50%" }}>
                        <h3>SensoryDescription</h3>
                        <TextEditingComponent
                            obj={region}
                            textSelector={(r: RegionResource) => r.sensoryDescription}
                            setText={(str: string) => region.sensoryDescription = str}
                            allowEditing={HasTalePermission(taleId, "EditTale")}
                            onSave={() => updateRegionsMutator.mutateAsync({ region, taleId })}
                        />
                    </div>
                </div>

            </>}
            <div id={"species_" + region.id.identifier} className="region-section">
                <h2>Species <FontAwesomeIcon icon={faDragon} /></h2>
                <CardVerticalScrollContainer
                    items={region.species}
                    taleId={taleId}
                    fallBackImage={() => DummyImages.Species}
                    baseUrl={taleUriBase(taleId) + "/species/"}
                    allowCreation={HasTalePermission(taleId, "EditTale")}
                />

            </div>

            <div id={"characters_" + region.id.identifier} className="region-section">
                <h2>Characters <FontAwesomeIcon icon={faPerson} /></h2>
                <CardVerticalScrollContainer
                    items={region.characters}
                    taleId={taleId}
                    fallBackImage={() => DummyImages.Character}
                    baseUrl={taleUriBase(taleId) + "/characters/"}
                    allowCreation={HasTalePermission(taleId, "EditTale")}
                />
            </div>

            <div id={"items_" + region.id.identifier} className="region-section">
                <h2>Items <FontAwesomeIcon icon={faTools} /></h2>
                <CardVerticalScrollContainer
                    items={region.items}
                    taleId={taleId}
                    fallBackImage={() => DummyImages.Item}
                    baseUrl={taleUriBase(taleId) + "/items/"}
                    allowCreation={HasTalePermission(taleId, "EditTale")}
                />
            </div>

            <div id={"regions_" + region.id.identifier} className="region-section">
                <h2>Sub-Regions <FontAwesomeIcon icon={faGlobe} /></h2>
                <CardVerticalScrollContainer
                    items={node.children.map(n => n.region)}
                    taleId={taleId}
                    baseUrl={taleUriBase(taleId) + "/region/"}
                    fallBackImage={(r: RegionResource) => DummyImages.Region(r.regionType)}
                    allowCreation={HasTalePermission(taleId, "EditTale")}
                    creationUrl={taleUriBase(taleId) + '/create-region-child/' + region.id.identifier}
                />
            </div>
        </div>
    )
}