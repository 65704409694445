import { FC } from "react"
import { Spinner } from "react-bootstrap"
import { ProposalResource } from "../../generated/BackendApiClient"
import { useSelectedRegion } from "../../services/SelectedRegionHook"
import { useCreateItemMutator } from "../../stores/tale/items/ItemMutators"
import { ManualPrompt } from "../ManualPrompt"
import { ProposalGenerator } from "../ProposalGenerator"
import { useNavigate } from "react-router-dom"
import { taleUriBase } from "../../services/UriBuilder"

export const TaleItemCreationSubPage: FC = () => {
    const { tale, selectedRegion } = useSelectedRegion()
    const { createItemMutator, creatingItem } = useCreateItemMutator()
    const nav = useNavigate()

    if (tale === undefined || selectedRegion === undefined) {
        return <div>Loading...</div>
    }

    const taleId = tale.id
    const regionId = selectedRegion.id
    const createNewItem = async (proposal: ProposalResource) => createItemMutator.mutateAsync({ taleId, regionId, proposal })
        .then(change => nav(taleUriBase(taleId) + '/items/' + change.changedId))

    return (
        <>
            <h2>Create New Item</h2>
            {<ManualPrompt onSelectedProposal={createNewItem} costSelection={c => c?.itemCreation} />}
            {<h3>Generate Proposals</h3>}
            {<ProposalGenerator taleId={taleId} onSelectedProposal={createNewItem} costSelection={c => c?.itemCreation} type="Item" />}
            {creatingItem ? <div>Creating Item... <Spinner /></div> : <></>}
        </>
    )
}