import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { TaleNavigation } from "../components/TaleNavigation";
import { TaleMetaData } from "../components/TaleVisualizations/TaleMetaData";
import "./TalePage.css"
import { TaleRegionSubPage } from "../components/TaleVisualizations/TaleRegionSubPage";
import { useSelectedTale } from "../services/SelectedTaleHook";
import { TaleRegionCreationSubPage } from "../components/TaleVisualizations/TaleRegionCreationSubPage";
import { TaleSpeciesSubPage } from "../components/TaleVisualizations/TaleSpeciesSubPage";
import { TaleCharacterSubPage } from "../components/TaleVisualizations/TaleCharacterSubPage";
import { TaleSpeciesCreationSubPage } from "../components/TaleVisualizations/TaleSpeciesCreationSubPage";
import { TaleCharacterCreationSubPage } from "../components/TaleVisualizations/TaleCharacterCreationSubPage";
import { TaleItemCreationSubPage } from "../components/TaleVisualizations/TaleItemCreationSubPage";
import { TaleItemSubPage } from "../components/TaleVisualizations/TaleItemSubPage";

export const TalePage: FC = () => {

    const { tale } = useSelectedTale()

    if (tale === undefined) {
        return <div>Loading Tale... <Spinner /></div>
    }

    const buildPageWith = (element: JSX.Element) => (
        <div className='mainContainer tale-page'>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
                <TaleNavigation tree={tale.regions} tale={tale} />
                <div className="content-page">
                    {element}
                </div>
            </div>
        </div>
    )

    return (
        <Routes>
            <Route path="/">
                <Route path="/" element={buildPageWith(<TaleMetaData tale={tale} />)} />
                <Route path="/world" element={buildPageWith(<TaleMetaData tale={tale} />)} />
                <Route path="/create-region-child/:regionId" element={buildPageWith(<TaleRegionCreationSubPage />)} />
                <Route path="/region/:regionId" element={buildPageWith(<TaleRegionSubPage />)} />
                <Route path="/regions" element={buildPageWith(<TaleRegionCreationSubPage />)} />
                <Route path="/species/:speciesId" element={buildPageWith(<TaleSpeciesSubPage />)} />
                <Route path="/species/create" element={buildPageWith(<TaleSpeciesCreationSubPage />)} />
                <Route path="/characters/:characterId" element={buildPageWith(<TaleCharacterSubPage />)} />
                <Route path="/characters/create" element={buildPageWith(<TaleCharacterCreationSubPage />)} />
                <Route path="/items/:itemId" element={buildPageWith(<TaleItemSubPage />)} />
                <Route path="/items/create" element={buildPageWith(<TaleItemCreationSubPage />)} />
            </Route>
        </Routes>
    )
}

