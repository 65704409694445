import { useState } from "react"
import { useUserToken } from "../../token/TokenQuery"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { GetBackendClient } from "../../../services/BackendClientFactory"
import { IntegrateUpdatedTale, IntegrateUpdatedTaleByUpdateResource } from "../TaleIntegrations"
import { IdResource, ItemResource, ProposalResource, TaleIdResource } from "../../../generated/BackendApiClient"
import { useUserSettingsQuery } from "../../user/settings/UserSettingsQueries"
import { invalidateCoins } from "../../user/resources/UserResourceMutators"

type ItemCreationArgs = {
    taleId: TaleIdResource,
    regionId: IdResource,
    proposal: ProposalResource
}

export const useCreateItemMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [creating, setCreating] = useState(false)

    const createItemMutator = useMutation({
        mutationFn: async (args: ItemCreationArgs) => {
            setCreating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).createItem.createItem(args.taleId.id, args.regionId.identifier, args.proposal)
            invalidateCoins(queryClient)
            setCreating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTaleByUpdateResource(queryClient)
    })

    return { createItemMutator, creatingItem: creating }
}

type UpdateItem = { item: ItemResource, taleId: TaleIdResource, regionId: IdResource }
export const useUpdateItemMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [updating, setUpdating] = useState(false)

    const updateItemMutator = useMutation({
        mutationFn: async (updateItem: UpdateItem) => {
            setUpdating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).updateItem.updateItem(updateItem.taleId.id, updateItem.regionId.identifier, updateItem.item)
            setUpdating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { updateItemMutator, updatingItem: updating }
}

type RemoveItem = { itemId: IdResource, taleId: TaleIdResource, regionId: IdResource }
export const useRemoveItemMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [removing, setRemoving] = useState(false)

    const removeItemMutator = useMutation({
        mutationFn: async (removeItem: RemoveItem) => {
            setRemoving(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).removeItem.removeItem(removeItem.taleId.id, removeItem.regionId.identifier, removeItem.itemId)
            setRemoving(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { removeItemMutator, removingItem: removing }
}