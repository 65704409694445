import { FC } from "react";
import { useSelectedItem } from "../../services/SelectedItemHook";
import { TaleItemView } from "./TaleItemView";

export const TaleItemSubPage: FC = () => {

    const { tale, parentRegion, item } = useSelectedItem()

    if (tale === undefined || parentRegion === undefined || item === undefined) {
        return <div>Loading Species...</div>
    }

    return (<TaleItemView taleId={tale.id} item={item} region={parentRegion} />)
} 