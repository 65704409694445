import { useParams } from "react-router-dom"
import { useSelectedTale } from "./SelectedTaleHook"
import { CollectRegions } from "../dtos/RegionTreeExtensions"

export const useSelectedItem = () => {
    const { tale } = useSelectedTale()
    const { itemId } = useParams()

    if (tale === undefined) {
        return { tale, parentRegion: undefined, item: undefined }
    }

    const regions = CollectRegions(tale.regions)
    const parentRegion = regions.find(region => region.items.some(item => item.id.identifier === itemId))!
    const item = parentRegion.items.find(item => item.id.identifier === itemId)!

    return { tale, parentRegion, item }
} 