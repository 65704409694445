import { FC } from "react";

type PadProps = {
    padding: number
}

export const Pad: FC<PadProps> = ({ padding }) => {
    return (
        <div style={{ paddingInline: padding }}></div>
    )
}