import { FC } from "react";
import { TaleSpeciesView } from "./TaleSpeciesView";
import { useSelectedSpecies } from "../../services/SelectedElementHook";

export const TaleSpeciesSubPage: FC = () => {

    const { tale, containingRegion, species } = useSelectedSpecies()

    if (tale === undefined || containingRegion === undefined || species === undefined) {
        return <div>Loading Species...</div>
    }

    return (<TaleSpeciesView taleId={tale.id} species={species} region={containingRegion} />)
} 