import { FC, useState } from "react"
import { RegionTreeNodeResource } from "../generated/BackendApiClient"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faCaretRight, faCat, faCircle, faContactBook, faEarthEurope, faLocation, faLocationDot, faPlus, faSolarPanel, faSpider, faSquare } from "@fortawesome/free-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { Link } from "react-router-dom"
import { taleUriBase } from "../services/UriBuilder"
import { useSelectedRegion } from "../services/SelectedRegionHook"
import "./NavRegion.css"

type NavRegionProps = {
    node: RegionTreeNodeResource,
    isParentOfSelected: (region: RegionTreeNodeResource) => boolean
}

export const NavRegion: FC<NavRegionProps> = ({ node, isParentOfSelected }) => {

    const { tale, selectedRegion } = useSelectedRegion()
    const [isOpen, setIsOpen] = useState(isParentOfSelected(node))
    const hasChildren = node.children.length > 0

    const collapsIcon = (<div onClick={() => setIsOpen(!isOpen)} className="icon-holder">
        {hasChildren && <FontAwesomeIcon icon={isOpen ? faCaretDown : faCaretRight} />}
    </div>)

    const getFaIconByRegionType = (type: string): IconProp => {
        switch (type) {
            case 'universe': return faCircle
            case 'galaxy': return faSpider
            case 'solarsystem': return faSolarPanel
            case 'celestialbody': return faEarthEurope
            case 'continent': return faContactBook
            case 'country': return faLocation
            case 'area': return faSquare
            case 'poi': return faLocationDot
            case 'segment': return faCat
            default: return faCat
        }
    }

    const isSelected = selectedRegion?.id.identifier === node.region.id.identifier

    const classes = ["nav-region"]

    if (isSelected) {
        classes.push("selected")
    }

    const regionIcon = (<div className="icon-holder">
        {<FontAwesomeIcon icon={getFaIconByRegionType(node.region.regionType)} />}
    </div>)

    const createSubRegionBtn = (
        <Link to={taleUriBase(tale?.id!) + '/create-region-child/' + node.region.id.identifier}><FontAwesomeIcon icon={faPlus} /></Link>
    )

    return (
        <>
            <div className={classes.join(" ")} style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', width: 'fit-content' }}>
                    {collapsIcon}
                    <Link to={taleUriBase(tale?.id!) + "/region/" + node.region.id.identifier}>{regionIcon}{node.region.name}</Link>
                </div>
                {createSubRegionBtn}
            </div>
            {isOpen && <div style={{ paddingLeft: 20 }}>{
                node.children.map(subregion => <NavRegion key={subregion.region.id.identifier} isParentOfSelected={isParentOfSelected} node={subregion} />)
            }</div>}
        </>
    )
}