import { useParams } from "react-router-dom"
import { useSelectedTale } from "./SelectedTaleHook"
import { CollectRegions } from "../dtos/RegionTreeExtensions"
import { RegionResource } from "../generated/BackendApiClient"

interface Identifyable {
    id: {identifier: string}
}

const useSelectedElement = <T extends Identifyable> (elementId: string|undefined, elementSelector: (region: RegionResource) => T[]) => {
    const { tale } = useSelectedTale()

    if(elementId === undefined) {
        throw new Error("ElementId not found")
    }
    

    if (tale === undefined) {
        return { tale, containingRegion: undefined, element: undefined }
    }

    const regions = CollectRegions(tale.regions)
    const containingRegion = regions.find(region => elementSelector(region).some(e => e.id.identifier === elementId))!
    const element = elementSelector(containingRegion).find(e => e.id.identifier === elementId)!

    return { tale, containingRegion, element }
}


export const useSelectedSpecies = () => {
    const { speciesId } = useParams()
    const {containingRegion, element: species, tale} = useSelectedElement(speciesId, containingRegion => containingRegion.species)
    return {containingRegion, species, tale}
} 

export const useSelectedCharacter = () => {
    const {characterId} = useParams()
    const {containingRegion, element: character, tale} = useSelectedElement(characterId, containingRegion => containingRegion.characters)
    return {containingRegion, character, tale}
}