import { FC } from "react";
import { useCreateSpeciesMutator } from "../../stores/tale/species/SpeciesMutators";
import { ManualPrompt } from "../ManualPrompt";
import { ProposalGenerator } from "../ProposalGenerator";
import { Spinner } from "react-bootstrap";
import { ProposalResource } from "../../generated/BackendApiClient";
import { useSelectedRegion } from "../../services/SelectedRegionHook";
import { useNavigate } from "react-router-dom";
import { taleUriBase } from "../../services/UriBuilder";

export const TaleSpeciesCreationSubPage: FC = () => {

    const { tale, selectedRegion } = useSelectedRegion()
    const { createSpeciesMutator, creatingSpecies } = useCreateSpeciesMutator()
    const nav = useNavigate()

    if (tale === undefined || selectedRegion === undefined) {
        return <div>Loading...</div>
    }

    const taleId = tale.id
    const regionId = selectedRegion.id
    const createNewSpecies = async (proposal: ProposalResource) => createSpeciesMutator.mutateAsync({ taleId, regionId, proposal })
        .then(change => nav(taleUriBase(taleId) + '/species/' + change.changedId))

    return (
        <>
            <h2>Create New Species</h2>
            {<ManualPrompt onSelectedProposal={createNewSpecies} costSelection={c => c?.speciesCreation} />}
            {<h3>Generate Proposals</h3>}
            {<ProposalGenerator taleId={taleId} onSelectedProposal={createNewSpecies} costSelection={c => c?.speciesCreation} type="Species" />}
            {creatingSpecies ? <div>Creating Species... <Spinner /></div> : <></>}
        </>
    )
} 